* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body styling */
body {
  font-family: Arial, sans-serif;
  background-color: #111;
  color: #fff;
  line-height: 1.6;
  margin: 0; /* Remova a margem padrão do corpo */
  overflow-x: hidden;
}

/* App styling */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  color: #fff;
  text-align: center;
}

header h1 {
  margin: 0;
  font-size: 2em;
}

main {
  flex: 1;
  min-height: 100vh;
}

section {
  padding: 20px;
  min-height: 100vh;
}

ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

li {
  margin-right: 20px;
}
