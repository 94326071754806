@import "~@fortawesome/fontawesome-free/css/all.min.css";

.section-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: flex-end; /* Alinha o conteúdo à direita */
}

.section-img {
  margin-top: 20px;
  margin-bottom: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://lh3.googleusercontent.com/d/1iD3NdNJooBm4-FHnfAw_efsYdmMonjeL");
  background-size: cover;
  z-index: -1;
  object-fit: cover;
}

.section-text {
  color: #333;
  background-color: rgba(240, 240, 240, 0.9);
  padding: 20px;
  height: 100%;
  border-radius: 10px;
  width: 70%;
  max-width: 800px;
  text-align: justify;
  margin: 10px;
  overflow: auto;
  font-size: calc(20px + (24 - 16) * (100vw - 400px) / (3000 - 400));
  text-shadow: 1px 1px 4px black;
}

.section-text h2 {
  margin-bottom: 20px;
  color: #111;
}

.section-text p {
  margin-top: 20px;
}

.section-text h2 p {
  margin-bottom: 10px;
}

.scroll-to-top-button {
  display: block;
}

@media screen and (max-width: 985px) {
  .section-container {
    justify-content: center;
  }

  .section-text {
    top: 0%;
    margin-top: 2%;
    left: 20px;
    height: 100%;
    width: calc(100% - 40px);
    text-align: justify;
    margin-right: 0;
    font-size: calc(16px + (24 - 16) * (100vw - 400px) / (1000 - 400));
  }

  .section-text h2 p {
    top: 0%;
    left: 20px;
    text-align: justify;
    margin-right: 0;
  }

  .scroll-to-top-button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 1px 1px 4px black;
  }

  .scroll-to-top-button:hover {
    background-color: #5a5a5a;
  }
}

@media screen and (min-width: 530px) {
  .scroll-to-top-button {
    display: none;
  }
}
