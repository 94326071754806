.certificate-container {
  color: #111;
  display: flex;
  margin: 20px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  text-shadow: 1px 1px 2px black;
}

.certificate {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  flex-wrap: wrap;
}

.certificate img {
  width: 100%; /* Definir largura máxima como 100% */
  max-width: 500px; /* Largura máxima */
  height: auto; /* Altura automática para manter a proporção */
  margin-right: 20px;
}

.certificate-info {
  max-width: 500px;
  padding: 10px;
  background-color: #f9f9f9; /* Cor de fundo */
  border: 1px solid #ccc; /* Borda */
  border-radius: 5px; /* Adiciona bordas arredondadas na parte inferior */
  margin-top: 20px;
}

.certificate-info h3 {
  margin-top: 10px;
}

/* Media query para dispositivos com largura máxima de 768px (tablets e smartphones) */
@media (max-width: 768px) {
  .certificate {
    margin-right: 0; /* Remover margem direita */
  }

  .certificate img {
    margin-right: 0; /* Remover margem direita */
  }

  .certificate-info {
    margin-right: 0; /* Remover margem direita */
    width: 100%; /* Largura total */
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading {
  font-size: 24px;
  color: #333;
}
