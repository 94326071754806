@import "~@fortawesome/fontawesome-free/css/all.min.css";

.skills-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.skills-img {
  margin-top: 20px;
  margin-bottom: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://lh3.googleusercontent.com/d/1iD3NdNJooBm4-FHnfAw_efsYdmMonjeL");
  background-size: cover;
  z-index: -1;
  object-fit: cover;
}

.skills-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #333;
  background-color: rgba(240, 240, 240, 0.9);
  padding: 20px;
  height: 95%;
  border-radius: 10px;
  width: 70%;
  max-width: 800px;
  text-align: justify;
  margin: 10px;
  overflow: auto;
  font-size: calc(20px + (24 - 16) * (100vw - 400px) / (3000 - 400));
  text-shadow: 1px 1px 4px black;
}

.skills-content h2 {
  margin-bottom: 20px;
  color: #111;
}

.skills-content ul {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  flex: 1;
}

.skills-content ul li {
  margin-bottom: 10px;
  list-style: disc;
  margin-top: 10px;
}

.skills-content p {
  margin-top: 20px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
}

.navigation-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 1px 1px 4px black;
}

.navigation-buttons button:hover {
  background-color: #5a5a5a;
}

.navigation-buttons button:disabled {
  background-color: #cccccc;
  box-shadow: none;
}

.section-button {
  font-size: 100px;
  background-color: transparent;
  transition: color 0.3s;
  box-shadow: none;
  border: none;
  color: #333;
  cursor: pointer;
  text-shadow: 1px 1px 4px black;
}

.section-button:disabled {
  color: rgba(240, 240, 240, 0.5);
  text-shadow: none;
}

.page-info {
  background-color: none;
  font-size: calc(10px + (24 - 16) * (100vw - 400px) / (3000 - 400));
}

@media screen and (max-width: 768px) {
  .skills-content {
    top: 0%;
    margin-top: 2%;
    left: 20px;
    height: 90%;
    width: calc(100% - 10px);
    text-align: justify;
    margin-right: 0;
    font-size: calc(20px + (24 - 16) * (100vw - 400px) / (3000 - 400));
  }

  .skills-content ul li {
    top: 0%;
    left: 20px;
    text-align: justify;
    margin-right: 0;
  }

  .section-button {
    font-size: calc(50px + (24 - 16) * (100vw - 400px) / (3000 - 400));
  }

  .section-button:disabled {
    font-size: calc(30px + (24 - 16) * (100vw - 400px) / (3000 - 400));
  }
}
