.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 10px 20px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.nav-bar::after {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  bottom: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: 4px;
  animation: glowing 20s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-links li {
  padding: 5px;
  border: none;
  outline: none;
  color: #fff;
  background: #309ee3;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  transition: transform 0.3s ease;
  width: 120px;
}

.nav-links li.active {
  transform: scale(1.05);
}

.nav-links li:last-child {
  margin-right: 0;
}

.nav-links li a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s;
  padding: 10px;
  display: block;
  width: 100%;
}

.nav-links li a:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.nav-links li.active a:before,
.nav-links li a:hover:before {
  opacity: 1;
}

.nav-links li a:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #2d8ee4;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.dropdown-btn {
  display: none;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 2rem;
  width: 100px;
}

.dropdown-btn p {
  font-size: 16px;
}

@media screen and (max-width: 820px) {
  .nav-links {
    display: none;
  }

  .dropdown-btn {
    display: block;
  }

  .mobile-nav {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    transition: opacity 0.3s, max-height 0.3s;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
    width: calc(100% - 40px);
  }

  .mobile-nav.active {
    max-height: 200px;
    pointer-events: auto;
    opacity: 1;
  }

  .mobile-nav li {
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d8ee4;
    border-radius: 10px;
    width: 120px;
  }

  .mobile-nav li a {
    text-align: center;
    padding: 10px;
    width: 100%;
  }

  .dropdown-btn {
    left: 20px;
  }
}

@media screen and (max-width: 430px) {
  .mobile-nav {
    padding: 5px;
    max-height: max-content;
  }

  .mobile-nav li {
    flex-wrap: wrap;
    width: 80px;
  }

  .mobile-nav li a {
    padding: 5px 0;
    font-size: 0.8rem;
    flex-wrap: wrap;
  }
}
