@import "~@fortawesome/fontawesome-free/css/all.min.css";

.contact-container {
  position: relative;
  height: 50%px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.contact-content {
  color: #111;
  background-color: rgba(240, 240, 240, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 70%;
  max-width: 800px;
  text-align: center;
  margin-right: 20px;
  margin-top: 20px;
  overflow: auto;
}

.contact-content i {
  margin-right: 10px; /* Espaço entre o ícone e o texto */
  font-size: 3em; /* Ajusta o tamanho do ícone */
}

.contact-content h2 {
  margin-bottom: 20px;
}

.contact-content p {
  margin-bottom: 10px;
  align-items: center;
}

.contact-content a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  font-size: 2.5em;
  text-shadow: 1px 1px 2px black;
}

.contact-content a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .contact-img {
    height: 30vw; /* Reduz ainda mais a altura da imagem para 30% da largura da tela em telas menores */
    max-height: 30vh; /* Limita a altura da imagem a 30% da altura da tela em telas menores */
  }

  .contact-content {
    width: 100%; /* Define a largura para 100% em telas menores */
    margin: 20px;
  }
}
