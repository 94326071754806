footer {
  color: #fff;
  text-align: center;
  padding: 10px 0;
  background-color: #333;
  position: relative;
  top: 20px;
  box-shadow: 8px 2px 0 rgba(0, 0, 0, 0.4);
}

footer p {
  margin: 0;
}

/* Adicione o pseudoelemento ::before para criar a borda animada */
footer::before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: 0; /* Posicione a borda no topo do footer */
  left: 0;
  width: 100%; /* Defina a largura para cobrir todo o footer */
  height: 4px; /* Altura da borda */
  z-index: -1;
  filter: blur(5px);
  background-size: 400%;
  animation: glowing 20s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

/* Adicione a animação ao pseudoelemento */
@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
