.projects-section {
  padding: 20px;
}

.projects-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.project {
  position: relative;
  color: #111;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-shadow: 1px 1px 2px black;
}

.project img {
  max-height: 550px;
  width: auto;
  margin-bottom: 20px;
  display: block;
}

.project h1 {
  font-size: 50px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.project p {
  margin-bottom: 10px;
  text-align: center;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.project a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.project a:hover {
  text-decoration: underline;
}

.project-border {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(5px);
  background-size: 400%;
  animation: glowing 20s linear infinite;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* Media query para dispositivos com largura máxima de 768px (tablets e smartphones) */
@media (max-width: 768px) {
  .project {
    flex-direction: column;
    align-items: center;
  }

  .project img {
    max-width: 100%;
    height: auto;
  }
}
